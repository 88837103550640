import React from "react"
import {useItemCalculator} from "../../hooks/useItemCalculator"

export const Item = ({item, hanlderDelete, index}) => {
    const {cantidad, sumarItem, restarCantidad} = useItemCalculator()
    return (
        <div className="item-drawer">
            <div className="item-header">
                <div className="image-container ">
                    <img src={item[0]?.tina?.icon} className="img-fluid " alt="" />
                </div>
                <div className="item-description-container">
                    <p className="text-description-title">{`Tina ${item[0]?.tina?.title}`}</p>
                    <p className="text-description">{item[0]?.tina?.descriptionCapacity.split(":")[1].trim()}</p>
                </div>
                <div className="item-counter-container">
                    <p
                        onClick={restarCantidad}
                        className="item-counter-button-styles item-counter-border-left text-white">
                        -
                    </p>
                    <p className="item-counter-button-styles2">{cantidad}</p>
                    <p onClick={sumarItem} className="item-counter-button-styles item-counter-border-right text-white">
                        +
                    </p>
                </div>
            </div>
            <div className="added">
                {item[0]?.terminationSelected?.title.trim() && (
                    <div>
                        <p className=" w-25 ">Terminación</p>
                        <p className=" w-50 text-right pr-2">{item[0]?.terminationSelected?.title.trim()}</p>
                        <button
                            onClick={() => hanlderDelete(item, index, "termination")}
                            type="button"
                            className="btn btn-outline  btn-sm  btn-sm mt-0 mb-0 pt-0 pb-0">
                            <i className="mdi mdi-close text-white " height="120" />
                        </button>
                    </div>
                )}

                {item[0]?.extraSelected?.title.trim() && (
                    <div>
                        <p className="w-25">agrega</p>
                        <p className="w-50 text-right pr-2">Iluminación</p>
                        <button
                            type="button"
                            className="btn btn-outline btn-sm mt-0 mb-0 pt-0 pb-0 "
                            onClick={() => hanlderDelete(item, index)}>
                            <i className="mdi mdi-close text-white " height="120" />
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
