import React from "react"
import {Col, Container, Row} from "reactstrap"
import img1 from "../assets/images/inta1.png"
import img2 from "../assets/images/insta2.png"
import img3 from "../assets/images/insta3.png"

export const FollowUS = ({contact}) => {
    return (
        <section id="followUS">
            <div
                style={{backgroundColor: "#36302D"}}
                className="pt-5 pb-4 d-flex flex-column justify-content-center align-items-center">
                <h3 style={{paddingTop: !contact ? 60 : 0}} className="text-white text-center">
                    Síguenos y descubre lo excepcional
                </h3>

                <Container className="d-flex mt-4 mb-5">
                    <Row>
                        <Col xs="12" md="4" sm="4" lg="4" className="pt-3">
                            <img src={img1} alt="galeria-instagram-1" style={{width: "100%"}} />
                        </Col>
                        <Col xs="12" md="4" sm="4" lg="4" className="pt-3">
                            <img src={img2} alt="galeria-instagram-1" style={{width: "100%"}} />
                        </Col>
                        <Col xs="12" md="4" sm="4" lg="4" className="pt-3">
                            <img src={img3} alt="galeria-instagram-1" style={{width: "100%"}} />
                        </Col>
                    </Row>
                </Container>
                <button
                    type="button"
                    className="btn btn-outline"
                    onClick={() => window.open("https://www.instagram.com/tinaspuritama.cl")}>
                    <h5 className="text-white text-center">Ver más en instagram</h5>
                </button>
            </div>
        </section>
    )
}
