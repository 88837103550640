import React, {useEffect, useLayoutEffect, useRef, useState} from "react"
import {Col, Form, FormGroup, Input, Label} from "reactstrap"
import UseEmail from "../hooks/useEmail"

export const Contact = () => {
    const ref = useRef(null)
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [width, setWidth] = useState(0)

    const {sendEmail, submitted} = UseEmail("https://api.emailjs.com/api/v1.0/email/send")
    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth)
    }, [])
    console.log({submitted})
    useEffect(() => {
        if (submitted === 200) {
            setName("")
            setPhone("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitted])
    console.log({width})
    return (
        <section id="contact">
            <div className="box-contact  mt-0 pt-4" ref={ref}>
                <h5 className="text-white pt-2 pb-2">INFORMACIÓN DE CONTACTO</h5>

                <Form style={{width: "60%"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <FormGroup row>
                            <Label for="exampleEmail" size="sm" sm={2} className="pr-4 text-white">
                                Nombre
                            </Label>
                            <Col sm={9} lg={10}>
                                <Input
                                    className="ml-3 formlulario"
                                    bsSize="sm"
                                    id="exampleEmail"
                                    name="name"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value)
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label
                                for="exampleEmail2"
                                size="sm"
                                sm={2}
                                className="pr-4 text-white"
                                style={{marginLeft: width <= 500 ? 0 : 30}}>
                                Celular
                            </Label>

                            <Col sm={7}>
                                <Input
                                    className="formlularioPhone "
                                    bsSize="sm"
                                    id="exampleEmail2"
                                    name="phone"
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value)
                                    }}
                                    style={{marginLeft: 45, overflow: "hidden", width: width <= 500 ? "87%" : "100%"}}
                                />
                                <span
                                    style={{
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        overflow: "hidden",
                                        height: 30.1,
                                        backgroundColor: "rgba(219, 219, 219, 1)",
                                        position: "absolute",
                                        top: 0.5,
                                        left: 24,
                                        display: "flex",
                                        alignItems: "center",
                                        borderBottomLeftRadius: 20,
                                        borderTopLeftRadius: 20,
                                        fontSize: 12,
                                    }}>
                                    +569
                                </span>
                            </Col>
                        </FormGroup>
                    </div>
                </Form>

                <div className=" d-flex justify-content-center align-items-end pt-2 mb-3">
                    <Col lg="4" className="d-flex justify-content-center  ">
                        <button
                            onClick={() => {
                                sendEmail({
                                    service_id: "service_7w772yt",
                                    template_id: "template_k6ts96i",
                                    user_id: "tIdngsiEpiKZFocWX",
                                    template_params: {
                                        name,
                                        phone,
                                    },
                                    accessToken: "dc080c1acc3e0e5ca4309b15f634339f",
                                })
                            }}
                            type="button"
                            style={{borderRadius: 12, backgroundColor: "#36302D"}}
                            className="btn  text-white pt-2 pb-2 pl-5 pr-5 btn-sm ">
                            ENVIAR
                        </button>
                    </Col>
                </div>

                <span className="text-white text-center">
                    ¡Gracias por tu interés! Te contactaremos porque mereces un relajo excepcional
                </span>
            </div>
        </section>
    )
}
