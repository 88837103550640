import {useState} from "react"

const UseEmail = (endpointUrl) => {
    const [submitted, setSubmitted] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    const sendEmail = (data) => {
        console.log("entra aca?")
        setLoading(true)
        setSubmitted(null)
        setError(undefined)

        console.log(JSON.stringify(data))
        fetch(endpointUrl, {
            crossDomain: true,
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((response) => {
            console.log({response})
            // Endpoint thinks that it's likely a spam/bot request, you need to change "spam protection mode" to "never" in HeroTofu forms
            if (response.status === 422) {
                setError(500)
                setLoading(false)
            }

            if (response.status !== 200) {
                setError(500)
                setLoading(false)
            }
            if (response.status === 200) {
                setSubmitted(200)
                setLoading(false)
            }
        })
    }

    return {
        submitted,
        loading,
        error,
        sendEmail,
    }
}

export default UseEmail
