import React, {Component} from "react"
import {Row, Col} from "reactstrap"
class Footer_link extends Component {
    render() {
        return (
            // Footer Link start
            <Row className="link-style">
                <Col lg={12}>
                    <div className="text-center">
                        <a className="text-white" href="www.tinaspuritama.cl">
                            www.tinaspuritama.cl
                        </a>
                        <p className="text-muted ">© All rights reserved. Tinas Puritama {new Date().getFullYear()}</p>
                    </div>
                </Col>
            </Row>
            //   Footer Link End
        )
    }
}

export default Footer_link
