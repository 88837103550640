import {useState} from "react"

export const useItemCalculator = () => {
    const [cantidad, setCantidad] = useState(0)

    const sumarItem = () => {
        if (cantidad >= 0) {
            setCantidad(cantidad + 1)
        }
    }
    const restarCantidad = () => {
        if (cantidad > 0) {
            setCantidad(cantidad - 1)
        }
    }

    return {cantidad, sumarItem, restarCantidad}
}
