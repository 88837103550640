import {Col, Container, Row} from "reactstrap"
import React, {Component} from "react"

import Email from "../../assets/images/icons/email.png"
import Face from "../../assets/images/icons/face.png"
import FooterImage from "../../assets/images/logo-foter1.png"
import FooterLink from "../Footer/Footer_link"
import Insta from "../../assets/images/icons/insta.png"
import Location from "../../assets/images/icons/location.png"
import LogoDark from "../../assets/images/logo-footer.png"
import Phone from "../../assets/images/icons/phone.png"
import Wsp from "../../assets/images/icons/wsp.png"

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            links: [
                {
                    id: 1,
                    title: "Venta empresas",
                    child: [
                        {title: "9 4752 9561", link: "#"},
                        {title: "Chiguayante\nRegión del Biobío", link: "#"},
                    ],
                },
                {
                    id: 2,
                    title: "Company",
                    child: [
                        {title: "Features", link: "#"},
                        {title: "Faq", link: "#"},
                        {title: "Contact us", link: "#"},
                    ],
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                {/* Footer Start */}
                <footer className="section bg-footer pb-5">
                    <Container>
                        <Row className="pb-5 mb-5">
                            <Col sm={12} lg={4} className="xs-center">
                                <div className="footer-info mt-4 ">
                                    <img src={LogoDark} className="imgFooterS" alt="logo-footer-logo-dark" />
                                </div>
                            </Col>
                            <Col sm={12} lg={4} className="xs-center">
                                <Row className="pl-0 md-lg-5">
                                    {/* Render Footer Link */}
                                    <Col lg={12}>
                                        <div className="mt-4">
                                            <h5 className="f-20 text-white">Venta Empresas</h5>
                                            <ul className="list-unstyled footer-link mt-3">
                                                <div
                                                    className="d-flex flex-row  align-items-center"
                                                    onClick={() => window.open("tel:+56947529561")}>
                                                    <img
                                                        src={Phone}
                                                        className="img-fluid iconFooter pt-0 mt-0 "
                                                        alt="logo-footer-telefono"
                                                    />
                                                    <h6 className="text-white ml-3 pt-0 mt-2 ">
                                                        <a className="text-white pt-1 mt-1 " href="tel:+56947529561">
                                                            9 4752 9561
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div className="d-flex flex-row  align-items-center ">
                                                    <img
                                                        src={Location}
                                                        className="img-fluid iconFooter"
                                                        alt="logo-footer-localizacion"
                                                    />
                                                    <div className="text-white ml-3">
                                                        <h6 className="text-white pt-2 " href="tel:+18506484200">
                                                            Chiguayante <br /> Región del Biobío
                                                        </h6>
                                                    </div>
                                                </div>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={6} md={6} lg={2} className="xs-center">
                                <div className="mt-4">
                                    <h5 className="f-20 text-center text-white">Háblanos</h5>
                                    <div className="subscribe mt-4 pt-1  ">
                                        <div className="d-flex flex-row justify-content-center align-items-center ">
                                            <>
                                                <button
                                                    onClick={() =>
                                                        window.open(
                                                            "https://wa.me/+56947529561?text=Hola%20me%interesan%20las%20tinas%20que%20estan%20vendiendo"
                                                        )
                                                    }
                                                    className="btn btn-outline"
                                                    style={{
                                                        paddingLeft: 0,
                                                        zIndex: 2000,
                                                        flex: 1,
                                                        paddingRight: 0,
                                                        marginRight: 0,
                                                        position: "relative",
                                                    }}>
                                                    <img
                                                        src={Wsp}
                                                        className="img-fluid iconFooter"
                                                        alt="logo-footer-wsp"
                                                    />
                                                </button>
                                            </>
                                            <>
                                                <button
                                                    onClick={() =>
                                                        window.open(
                                                            "mailto:ventas@tinaspuritama.cl?subject=Subject&body=Me%interesan%20sus%20tinas"
                                                        )
                                                    }
                                                    className="btn btn-outline"
                                                    style={{
                                                        zIndex: 2000,
                                                        flex: 1,
                                                        position: "relative",
                                                        paddingLeft: 0,
                                                        marginLeft: 0,
                                                    }}>
                                                    <img
                                                        src={Email}
                                                        className="img-fluid iconFooter ml-3"
                                                        alt="logo-footer-Email"
                                                    />
                                                </button>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} md={6} lg={2} className="xs-center">
                                <div className="mt-4">
                                    <h5 className="f-20 text-center text-white">Síguenos</h5>
                                    <div className="subscribe mt-4 pt-1">
                                        <div className="d-flex flex-row justify-content-center align-items-center  pl-1 ">
                                            <>
                                                <button
                                                    onClick={() =>
                                                        window.open("https://www.facebook.com/tinaspuritama.cl")
                                                    }
                                                    className="btn btn-outline"
                                                    style={{
                                                        paddingLeft: 0,
                                                        zIndex: 2000,
                                                        flex: 1,
                                                        paddingRight: 0,
                                                        marginRight: 0,
                                                        position: "relative",
                                                    }}>
                                                    <img
                                                        src={Face}
                                                        className="img-fluid iconFooter"
                                                        alt="logo-footer-facebook"
                                                    />
                                                </button>
                                            </>
                                            <>
                                                <button
                                                    onClick={() =>
                                                        window.open("https://www.instagram.com/tinaspuritama.cl")
                                                    }
                                                    className="btn btn-outline"
                                                    style={{
                                                        zIndex: 2000,
                                                        flex: 1,
                                                        position: "relative",
                                                        paddingLeft: 0,
                                                        marginLeft: 0,
                                                    }}>
                                                    <img
                                                        src={Insta}
                                                        className="img-fluid iconFooter ml-3"
                                                        alt="logo-footer-instagram"
                                                    />
                                                </button>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <FooterLink />
                        {/* Render Footer Link End */}
                    </Container>

                    <div class="imgFooter">
                        <div className="d-flex  justify-content-center  align-items-center ">
                            <img
                                src={FooterImage}
                                className="img-fluid water-footer "
                                alt="logo-footer-logo-tinas-puritama"
                            />
                        </div>
                    </div>
                </footer>

                {/* Footer End */}
            </React.Fragment>
        )
    }
}

export default Footer
