import {Collapse, Container, Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler} from "reactstrap"
import React, {useState} from "react"
import ScrollspyNav from "./Scrollspy"
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import cartIcon from "../../assets/images/icons/wsp-1.png"
import useWindowDimensions from "../../hooks/useWindowDimensions"

// Import Logo

const NavbarPage = ({openCart, imglight, top, navClass, navItems, infoCart}) => {
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const {width} = useWindowDimensions()

    const toggle = () => {
        setIsOpenMenu(!isOpenMenu)
    }

    let targetId = navItems.map((item) => {
        return item.idnm
    })

    return (
        <React.Fragment>
            <Navbar
                expand="lg"
                fixed={top === true ? "top" : ""}
                className={navClass + " navbar-custom sticky sticky-dark"}
                id="navbar">
                <Container>
                    {/* LOGO */}
                    <NavbarBrand className="navbar-brand logo text-uppercase" href="/">
                        {imglight === true ? (
                            <img src={logolight} alt="" height="60" />
                        ) : (
                            <img src={logodark} alt="" height="40" />
                        )}
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle}>
                        <div className="d-flex justify-content-center align-items-center">
                            <i className="mdi mdi-menu text-white"></i>
                            <button onClick={openCart} type="button" className=" btn d-flex align-items-center pb-3 ">
                                <div style={{position: "relative"}}>
                                    <img src={cartIcon} alt="" height="25" style={{marginTop: 8}} />
                                    {/* {infoCart?.length > 0 && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: -15,
                                                right: -17,
                                                width: 20,
                                                height: 20,
                                                borderRadius: 20,
                                                backgroundColor: "red",
                                            }}>
                                            <div className="d-flex justify-content-center align-items-center  ">
                                                <span className="text-white text-bold">{infoCart?.length}</span>
                                            </div>
                                        </div>
                                    )} */}
                                </div>
                            </button>
                        </div>
                    </NavbarToggler>
                    <Collapse id="navbarCollapse" isOpen={isOpenMenu} className=" navbar-collapse">
                        <ScrollspyNav
                            width={width}
                            scrollTargetIds={targetId}
                            scrollDuration="800"
                            headerBackground="true"
                            activeNavClass="active"
                            className="navbar-collapse">
                            <Nav className="navbar-nav ml-auto navbar-center" id="navbar-navlist">
                                {navItems.map((item, key) =>
                                    item?.navheading === "Me interesa" && width >= 1010 ? (
                                        <button onClick={openCart} type="button" className="btn ">
                                            <div
                                                className="border"
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    borderRadius: 10,
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                }}>
                                                <img src={cartIcon} alt="" height="25" />
                                                <NavLink
                                                    className={
                                                        item.navheading === "Home" ? "active text-white " : "text-white"
                                                    }
                                                    href={"#" + item.idnm}>
                                                    {item.navheading}
                                                </NavLink>
                                            </div>
                                        </button>
                                    ) : (
                                        item?.navheading !== "cart" && (
                                            <NavItem
                                                key={key}
                                                className={
                                                    (item.navheading === "Home" ? "active" : " ",
                                                    imglight === false ? "text-white" : "text-white")
                                                }
                                                style={{display: "flex", alignItems: "center"}}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        borderRadius: 10,
                                                        backgroundColor: "rgba(120, 188, 223, 1)",
                                                    }}>
                                                    <NavLink
                                                        className={
                                                            item.navheading === "Home"
                                                                ? "active text-white "
                                                                : " text-white"
                                                        }
                                                        style={{padding: 5, fontWeight: "bold"}}
                                                        href={"#" + item.idnm}>
                                                        {item.navheading}
                                                    </NavLink>
                                                </div>
                                            </NavItem>
                                        )
                                    )
                                )}
                            </Nav>
                        </ScrollspyNav>
                    </Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    )
}
// }
export default NavbarPage
