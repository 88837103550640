import React, {useState} from "react"
import {Col, Container, Row} from "reactstrap"
import Baltinache from "../assets/images/tinajas/baltinache.png"
import Licanbur from "../assets/images/tinajas/licancabur.png"
import hojaBlanca from "../assets/images/icons/hoja-blanca.png"
import hojaBlanca2 from "../assets/images/icons/Hoja2blanca2.png"
import Tatio from "../assets/images/tinajas/tatio.png"
import Love from "../assets/images/tinajas/tina-amor.png"
// import useWindowDimensions from "../hooks/useWindowDimensions"

const extra = [
    {
        index: "extra-1",
        title: "Iluminación",
    },
    {
        index: "extra-2",
        title: "Pintado interior",
    },
    {
        index: "extra-3",
        title: "Plataforma de piso",
    },
    {
        index: "extra-4",
        title: "Cubierta protectora de lona a medida",
    },
    {
        index: "extra-5",
        title: "Kit de filtración",
    },
    {
        index: "extra-6",
        title: "Hidromasaje (agua y burbujas)",
    },
]

// const termination = [
//     {
//         title: "Estándar",
//         button: "Añadir gratis",
//         description: [
//             {index: "e-01", title: "70 cm"},
//             {index: "e-02", title: "1 m"},
//         ],
//     },
//     {
//         title: "Pintado exterior",
//         button: "Añadir terminación exclusiva",
//         description: [
//             {index: "m-01", title: "Barniz"},
//             {index: "m-02", title: "Café"},
//             {index: "m-03", title: "Negro"},
//         ],
//     },
//     {
//         title: "Caldera",
//         button: "Añadir terminación exclusiva",
//         description: [
//             {index: "a-01", title: "Pellet"},
//             {index: "a-02", title: "Leña"},
//         ],
//     },
// ]

const pricing = [
    {
        id: 0,
        title: "Amor",
        descriptionCapacity: "Capacidad: 2-4 personas",
        descripcionTime: "Tiempo de preparación: 2 horas",
        descriptionDimension: "Dimensiones: 1,4x2,4x1 m",
        descriptionPeso: "Peso: 180kg",
        icon: Love,
        titlePrimary: false,
        pricingTag: false,
        child: [
            {
                btitle: "Unlimited",
                title: "Target Audience",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "1",
                title: "User Account",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "100+",
                title: "Video Tuts",
                icon: "mdi-close-circle text-danger",
            },
            {
                btitle: "Public",
                title: "Displays",
                icon: "mdi-close-circle text-danger",
            },
        ],
    },
    {
        id: 1,
        title: "Tatio",
        descriptionCapacity: "Capacidad: 2-4 personas",
        descripcionTime: "Tiempo de preparación: 2 horas",
        descriptionDimension: "Dimensiones: 1,4x2,4x1 m",
        descriptionPeso: "Peso: 180kg",
        icon: Tatio,
        titlePrimary: false,

        pricingTag: false,
        child: [
            {
                btitle: "Unlimited",
                title: "Target Audience",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "1",
                title: "User Account",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "100+",
                title: "Video Tuts",
                icon: "mdi-close-circle text-danger",
            },
            {
                btitle: "Public",
                title: "Displays",
                icon: "mdi-close-circle text-danger",
            },
        ],
    },
    {
        id: 2,
        title: "Baltinache",
        titlePrimary: true,
        descriptionCapacity: "Capacidad: 6-8 personas",
        descripcionTime: "Tiempo de preparación: 3 horas",
        descriptionDimension: "Dimensiones: 2x2x1 m",
        descriptionPeso: "Peso: 200kg",
        icon: Licanbur,

        pricingTag: true,
        child: [
            {
                btitle: "Unlimited",
                title: "Target Audience",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "1",
                title: "User Account",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "100+",
                title: "Video Tuts",
                icon: "mdi-close-circle text-success",
            },
            {
                btitle: "Public",
                title: "Displays",
                icon: "mdi-close-circle text-danger",
            },
        ],
    },
    {
        id: 3,
        title: "Licancabur",
        descriptionCapacity: "Capacidad: 6-8 personas",
        descripcionTime: "Tiempo de preparación: 4 horas",
        descriptionDimension: "Dimensiones: 2,2x2,3x1 m",
        descriptionPeso: "Peso: 230 kg",
        titlePrimary: false,
        icon: Baltinache,
        pricingTag: false,
        child: [
            {
                btitle: "Unlimited",
                title: "Target Audience",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "1",
                title: "User Account",
                icon: "mdi-checkbox-marked-circle text-success",
            },
            {
                btitle: "100+",
                title: "Video Tuts",
                icon: "mdi-close-circle text-success",
            },
            {
                btitle: "Public",
                title: "Displays",
                icon: "mdi-close-circle text-success",
            },
        ],
    },
]

const PricingComponent = ({setInformation, openCart, cotization, setCotization, showContact, ...props}) => {
    // const {width} = useWindowDimensions()
    const [TinaSelected] = useState(null)
    // const [terminationSelected, setTerminationSelected] = useState(null)
    // const [extraSelected, setExtraSelected] = useState(null)

    // const resetStates = () => {
    //     setTinaSelected(null)
    //     setTerminationSelected(null)
    //     setExtraSelected(null)
    // }

    // const isTinaSelected = ({item}) => {
    //     if (TinaSelected) {
    //         setTinaSelected(null)
    //     } else {
    //         goNextSection("selectTermination")
    //         setTinaSelected(item)
    //     }
    // }

    // const handlerTerminationSelected = (item) => {
    //     if (terminationSelected) {
    //         setTerminationSelected(null)
    //     } else {
    //         goNextSection("Extras")
    //         setTerminationSelected(item)
    //     }
    // }
    // const handlerExtraSelected = (item) => {
    //     if (extraSelected) {
    //         setExtraSelected(null)
    //     } else {
    //         setExtraSelected(item)
    //     }
    // }

    // const handlerCotization = () => {
    //     if (TinaSelected && terminationSelected) {
    //         setCotization([...cotization, [{tina: TinaSelected, terminationSelected, extraSelected}]])
    //         setInformation([...cotization, [{tina: TinaSelected, terminationSelected, extraSelected}]])
    //         resetStates()
    //         goNextSection("pricing")
    //     } else {
    //         alert("Por favor elija una tina y luego elija su terminación.")
    //     }
    // }

    // const scrollTo = (start, to, duration) => {
    //     let change = to - start,
    //         currentTime = 0,
    //         increment = 10

    //     let animateScroll = () => {
    //         currentTime += increment
    //         let val = easeInOutQuad(currentTime, start, change, duration)
    //         window.scrollTo(0, val)
    //         if (currentTime < duration) {
    //             setTimeout(animateScroll, increment)
    //         }
    //     }

    //     animateScroll()
    // }

    // const easeInOutQuad = (current_time, start, change, duration) => {
    //     current_time /= duration / 2
    //     if (current_time < 1) return (change / 2) * current_time * current_time + start
    //     current_time--
    //     return (-change / 2) * (current_time * (current_time - 2) - 1) + start
    // }

    // const goNextSection = (tagID) => {
    //     let scrollTargetPosition =
    //         document.getElementById(tagID).offsetTop -
    //         document.querySelector("div[data-nav='list']").scrollHeight -
    //         (width > 1012 ? (tagID === "pricing" ? -50 : 0) : 120)
    //     scrollTo(window.pageYOffset, scrollTargetPosition - 38, 300)
    // }

    return (
        <React.Fragment>
            <section className="section bg-bluex  pt-5 pb-5" id="pricing">
                <Container>
                    <img
                        style={{position: "absolute", width: "30vw", right: 0, top: 10}}
                        src={hojaBlanca}
                        className="img-fluid"
                        alt="hoja-armonica"
                    />
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col lg={12}>
                            <div>
                                <h2 className="text-center text-white text-uppercase font-weight-bold">
                                    Todas nuestras tinas incluyen
                                </h2>
                                <p
                                    className="text-center text-uppercase  font-weight-bold text-white text-center mt-4"
                                    style={{fontSize: 15}}>
                                    Termómetro - Escalera - Remo - Posa vasos - Llave de paso - Caldera a leña - Pintado
                                    exterior Cañón y gorro de zinc - Interior con madera nativa - Revestimiento con
                                    madera (pino)
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section bg-gris">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="title-box text-center">
                                <h1 className=" text-white">Nuestros modelos</h1>
                                <h6 className=" text-white">Con capacidad desde 2 hasta 8 personas</h6>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 pt-4 d-flex  align-items-center justify-content-center ">
                        {pricing.map((item, key) => (
                            <Col lg="3" key={key} className="btn flex align-items-center justify-content-center">
                                <div
                                    className={`${
                                        TinaSelected
                                            ? TinaSelected?.title === item?.title
                                                ? "pricing-box"
                                                : "pricing-box-disabled"
                                            : "pricing-box"
                                    }  mt-4 d-flex flex-column`}
                                    ß>
                                    {item.pricingTag && (
                                        <div className="pricing-badge">
                                            <span className="badge">Mas Vendida</span>{" "}
                                        </div>
                                    )}
                                    <div className="bg-red d-flex justify-content-center align-items-center">
                                        <img
                                            src={item.icon}
                                            style={{height: 270}}
                                            className="img-fluid mb-3 "
                                            alt="logo-cotizacion-tinajas"
                                        />
                                    </div>
                                    {item.titlePrimary === true ? (
                                        <h2 className="text-primary text-center">{item.title}</h2>
                                    ) : (
                                        <h2 className="text-primary text-center">{item.title}</h2>
                                    )}

                                    <p className="pl-3 mt-4 pt-2 text-white description">{item.descriptionCapacity}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            {/* <section id="selectTermination" className="section bg-gris mt-0 pt-4">
                <Container>
                    <h2 className="text-center text-white">Escoge gratis</h2>
                    <h6 className="text-center text-uppercase font-weight-bold text-white ">(Sólo una)</h6>
                    <Row className="d-flex align-items-center justify-content-center ">
                        {termination.map((item, key) => (
                            <Col lg="4" key={key} className="flex align-items-center justify-content-center">
                                <div
                                    className={`${
                                        terminationSelected
                                            ? terminationSelected?.title === item?.title
                                                ? "termination-box"
                                                : "termination-box-disabled"
                                            : "termination-box"
                                    }  mt-4 d-flex flex-column align-items-center`}>
                                    <div className="d-flex-column">
                                        <h3 className="text-white text-center ">{item.title}</h3>
                                        <div className="d-flex">
                                            {item?.description.map((element, keys) => (
                                                <div className="badge-termination mb-4 ml-2">
                                                    {item.titlePrimary === true ? (
                                                        <span className="text-primary btn-type text-center  ">
                                                            {element.title}
                                                        </span>
                                                    ) : (
                                                        <span className=" btn-type text-center">{element.title}</span>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section> */}

            <section id="Extras" className="section bg-gris mt-0 pt-4">
                <Container>
                    <h2 className="text-center text-white">También, puedes agregar</h2>
                    <Row className="d-flex align-items-center justify-content-center ">
                        {extra.map((item, key) => (
                            <Col
                                md="4"
                                lg="4"
                                sm="4"
                                xs="6"
                                key={key}
                                className=" flex align-items-center justify-content-center">
                                <div className=" btn extra-box mt-4 d-flex flex-column  justify-content-center align-items-center ">
                                    {item.titlePrimary === true ? (
                                        <span className="text-white plan text-center">{item.title}</span>
                                    ) : (
                                        <span className="text-white plan text-center">{item.title}</span>
                                    )}
                                </div>
                            </Col>
                        ))}
                    </Row>

                    <div className=" d-flex justify-content-center align-items-end mt-5  pt-5 ">
                        <Col lg="4" className="d-flex justify-content-center  ">
                            <button
                                onClick={() => showContact((prev) => !prev)}
                                type="button"
                                style={{borderRadius: 12}}
                                className="btn  text-white pt-2 pb-2 pl-5 pr-5 btn btn-primary">
                                Quiero que me contacten
                            </button>
                        </Col>
                    </div>
                </Container>
                <img
                    style={{position: "absolute", width: "35vw", left: 0, bottom: -80}}
                    src={hojaBlanca2}
                    className="img-fluid"
                    alt="hoja-armonica"
                />
            </section>
        </React.Fragment>
    )
}
export default PricingComponent
