import React from "react"

export const SuccesfullFullDrawer = () => {
    return (
        <div className="p-5 text-center">
            <h4>Gracias por hacernos saber que mereces un relajo excepcional</h4>
            <h4 className="text-primary pt-4">Muy pronto te responderemos :)</h4>
        </div>
    )
}
