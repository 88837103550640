import React from "react"
import {Item} from "./item"

export const ItemDrawer = ({dataFilter, setDataFilter}) => {
    const hanlderDelete = (_, index, type) => {
        const deleteTermination = dataFilter.map((element, postion) => {
            if (postion === index) {
                if (type === "termination") {
                    const terminationSelected = null
                    const tina = element[0].tina
                    const extraSelected = element[0].extraSelected
                    return [{tina, extraSelected, terminationSelected}]
                } else {
                    const terminationSelected = element[0].terminationSelected
                    const tina = element[0].tina
                    const extraSelected = null
                    return [{tina, extraSelected, terminationSelected}]
                }
            }
            return element
        })
        setDataFilter(deleteTermination)
    }

    if (dataFilter.length === 0) {
        return (
            <div>
                <h6 className="text-center"> Selecciona una tina para continuar</h6>
            </div>
        )
    }
    return dataFilter.map((item, index) => {
        return <Item key={index} {...{hanlderDelete, item, index}} />
    })
}
