/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {Spinner} from "reactstrap"
import {COffcanvas, COffcanvasHeader, COffcanvasTitle, COffcanvasBody} from "@coreui/react"
import {ItemDrawer} from "./drawerComponents/iItemDrawer"
import {ContactDrawer} from "./drawerComponents/ContactDrawer"
import {SuccesfullFullDrawer} from "./drawerComponents/SuccefullDrawer"
import UseEmail from "../hooks/useEmail"
import {Helmet} from "react-helmet"
const DrawerPay = ({showDrawerCart, HideDrawerCart, infoCart, setInfoCart, setCotization}) => {
    const [dataFilter, setDataFilter] = useState(infoCart)
    const [name, setName] = useState("")
    const [lastname, setLastname] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [stret, setStret] = useState("")
    const [stretNumber, setStretNumber] = useState("")
    const [region, setRegion] = useState("")
    const [comuna, setComuna] = useState("")
    const [checkone, setCheckOne] = useState(false)
    const [checkSecond, setCheckSecond] = useState(true)
    const [step, setStep] = useState(0)

    const {loading, sendEmail, submitted} = UseEmail("https://api.emailjs.com/api/v1.0/email/send")
    useEffect(() => {
        setDataFilter(infoCart)
    }, [infoCart])

    const nextStep = () => {
        if (step === 1) {
            if (validateInputs()) {
                sendExample()
            }
        } else {
            setStep(step + 1)
        }
    }
    const goBackStep = () => {
        setStep(step - 1)
    }

    const HideDrawerCartFunctionDelete = () => {
        setStep(0)
        HideDrawerCart()
    }

    const resetStates = () => {
        setName("")
        setLastname("")
        setPhone("")
        setEmail("")
        setStret("")
        setStretNumber("")
        setRegion("")
        setComuna("")
        setCheckSecond(true)
        setCheckOne(false)
        setDataFilter([])
        setInfoCart([])
        setCotization([])
    }
    useEffect(() => {
        if (submitted === 200) {
            resetStates()
            setStep(step + 1)
        } else {
        }
    }, [submitted])
    let aux = ""
    const validateInputs = () => {
        if (name === "") {
            alert("El Nombre es requerido")
            return false
        } else if (lastname === "") {
            alert("El Apellido es requerido")
            return false
        } else if (setEmail === "") {
            alert("El Email es requerido")
            return false
        } else if (setPhone === "") {
            alert("El Telefono es requerido")
            return false
        } else if (checkone) {
            if (setStret === "" || setStretNumber === "") {
                alert("La calle y numero es requerida")
                return false
            }
            if (comuna === "" || region === "") {
                alert("La comuna y region es requerida")
                return false
            }
        }
        return true
    }
    const sendExample = async () => {
        infoCart.map((item, index) => {
            aux = aux + "\n " + (index + 1) + " --->"
            if (item[0].tina.title) {
                aux = aux + " tina: " + item[0].tina.title
            }

            if (item[0]?.terminationSelected?.title) {
                aux = aux + "|| terminacion: " + item[0]?.terminationSelected?.title
            }
            if (item[0]?.extraSelected?.title) {
                aux = aux + "|| Extra: " + item[0]?.extraSelected?.title
            }
        })

        // Can be any data, static and dynamic

        sendEmail({
            service_id: "service_7w772yt",
            template_id: "template_k6ts96i",
            user_id: "user_iuWHttOQLxGCfK9aFG16Q",
            template_params: {
                firstname: name,
                lastname: lastname,
                phone: phone,
                email: email,
                despacho: checkone ? "Con Despacho" : "Sin Despacho",
                tinaSelected: aux,
                street: stret,
                streetnumber: stretNumber,
                comuna: comuna,
                region: region,
            },
            accessToken: "dc080c1acc3e0e5ca4309b15f634339f",
        })
    }

    const buttonDisabled = dataFilter.length === 0
    return (
        <>
            {showDrawerCart && (
                <Helmet>
                    <title>cotiza tu tina || cotiza tu tinaja || Tinas Puritamas </title>
                    <description>
                        cotiza tu tina caliente, tenemos stock de tinajas, nuestras tinajas son galvanizadas, y tiene
                        una duracion de 5 años, son las mejores tinajas del sur de chile. tinas calientes. tinajas
                        calientes,.
                    </description>
                </Helmet>
            )}

            <COffcanvas
                backdrop={true}
                placement="end"
                portal={false}
                onHide={HideDrawerCart}
                visible={showDrawerCart}
                className="bg-modal">
                <COffcanvasHeader className=" ">
                    {step > 0 && step < 2 && (
                        <button type="button" className="btn btn-outline" onClick={goBackStep}>
                            <i className="mdi mdi-arrow-left text-white " height="120" />
                        </button>
                    )}
                    <COffcanvasTitle
                        className={`w-100 ${step === 0 ? "pl-5" : step === 1 && "mr-5"} text-center title-color `}>
                        {step <= 1 ? "Su Solicitud" : "Exito"}
                    </COffcanvasTitle>

                    {step === 0 && (
                        <button type="button" className="btn btn-outline" onClick={HideDrawerCart}>
                            <i className="mdi mdi-close text-white " height="120" />
                        </button>
                    )}
                </COffcanvasHeader>
                <COffcanvasBody className="text-white">
                    <div className="body-wrapper">
                        {step === 0 && (
                            <div>
                                <ItemDrawer {...{dataFilter, setDataFilter}} />
                            </div>
                        )}

                        {step === 1 && (
                            <ContactDrawer
                                {...{
                                    name,
                                    setName,
                                    lastname,
                                    setLastname,
                                    phone,
                                    setPhone,
                                    email,
                                    setEmail,
                                    stret,
                                    setStret,
                                    stretNumber,
                                    setStretNumber,
                                    region,
                                    setRegion,
                                    comuna,
                                    setComuna,
                                    step,
                                    setStep,
                                    checkone,
                                    setCheckOne,
                                    checkSecond,
                                    setCheckSecond,
                                }}
                            />
                        )}
                        {step === 2 && <SuccesfullFullDrawer />}
                        <div className="footer-wrapper mt-4">
                            {step <= 1 ? (
                                <>
                                    <button
                                        disabled={buttonDisabled || loading}
                                        type="button"
                                        onClick={nextStep}
                                        className="btn btn-primary btn-primary-custom btn-round mb-1 d-flex justify-content-center align-items-center">
                                        Siguiente
                                        {loading && (
                                            <Spinner
                                                className="ml-3"
                                                style={{width: "0.7rem", height: "0.7rem"}}
                                                color="light"
                                            />
                                        )}
                                    </button>
                                    <button
                                        disabled={loading}
                                        type="button"
                                        onClick={HideDrawerCart}
                                        className="btn btn-secondary btn-secondary-custom btn-round mt-1 mb-5">
                                        Continuar agregando
                                    </button>
                                </>
                            ) : (
                                <button
                                    type="button"
                                    onClick={HideDrawerCartFunctionDelete}
                                    className="btn btn-secondary btn-secondary-custom btn-round mt-1 mb-5">
                                    Regresar a Inicio
                                </button>
                            )}
                        </div>
                    </div>
                </COffcanvasBody>
            </COffcanvas>
        </>
    )
}

export default DrawerPay
