import React, {useRef} from "react"
import {Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap"

import useCollapse from "react-collapsed"

export const ContactDrawer = ({
    name,
    setName,
    lastname,
    setLastname,
    phone,
    setPhone,
    email,
    setEmail,
    stret,
    setStret,
    stretNumber,
    setStretNumber,
    region,
    setRegion,
    comuna,
    setComuna,
    step,
    setStep,
    checkone,
    setCheckOne,
    checkSecond,
    setCheckSecond,
}) => {
    const checkboxRef = useRef(null)
    const {getCollapseProps: getCollapsePropsas} = useCollapse({
        isExpanded: checkone,
        duration: 600,
    })

    const handlerCheckbox = (e) => {
        if (checkone && !checkSecond) {
            setCheckOne(false)
            setCheckSecond(true)
        } else {
            setCheckOne(true)
            setCheckSecond(false)
        }
    }
    return (
        <div className="contact-container">
            <Container>
                <Row className="pt-1">
                    <Col lg={12}>
                        <div className="title-box text-left">
                            <h5 className="mt-4 text-white">Información de contacto</h5>
                        </div>
                    </Col>
                </Row>
                <Form method="post" name="contact-form" id="contact-form">
                    <FormGroup>
                        <Label className="text-white pb-0 mb-0">Nombre</Label>
                        <Input
                            className="form-control  form-control-sm pt-0 mt-0 rounded"
                            type="text"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className="text-white pb-0 mb-0">Apellido</Label>
                        <Input
                            name="lastName"
                            id="lastName"
                            className="form-control form-control-sm pt-0 mt-0 rounded"
                            type="text"
                            value={lastname}
                            onChange={(e) => {
                                setLastname(e.target.value)
                            }}
                        />
                    </FormGroup>
                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <Label className="text-white pb-0 mb-0">Celular</Label>
                                <Input
                                    name="lastName"
                                    id="lastName"
                                    className="form-control form-control-sm pt-0 mt-0 br-4 rounded"
                                    type="text"
                                    value={phone}
                                    onChange={(e) => {
                                        setPhone(e.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Label className="text-white pb-0 mb-0">Email</Label>
                                <Input
                                    name="phone"
                                    id="phone"
                                    className="form-control form-control-sm pt-0 mt-0 rounded"
                                    type="text"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row lg={12}>
                        <FormGroup className="mt-3 d-flex   align-items-center">
                            <Label className="text-white pb-0 mb-0 pr-4">¿Necesitas despacho?</Label>

                            <div className="custom-control custom-checkbox  pr-4">
                                <Input
                                    ref={checkboxRef}
                                    onChange={(e) => handlerCheckbox(e)}
                                    type="checkbox"
                                    checked={checkone}
                                    className="custom-control-input"
                                    id="customControlInline"
                                />
                                <Label className="custom-control-label text-white" for="customControlInline">
                                    Si
                                </Label>
                            </div>
                            <div className="custom-control custom-checkbox pl-4">
                                <Input
                                    type="checkbox"
                                    className="custom-control-input "
                                    id="customControlInlines"
                                    onChange={(e) => handlerCheckbox(e)}
                                    checked={checkSecond}
                                />
                                <Label className="custom-control-label text-white" for="customControlInlines">
                                    No
                                </Label>
                            </div>
                        </FormGroup>
                    </Row>
                    <div {...getCollapsePropsas()}>
                        <Row>
                            <Col lg={9}>
                                <FormGroup>
                                    <Label className="text-white pb-0 mb-0">Calle</Label>
                                    <Input
                                        name="stret"
                                        id="stret"
                                        className="form-control form-control-sm pt-0 mt-0"
                                        type="text"
                                        value={stret}
                                        onChange={(e) => {
                                            setStret(e.target.value)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={3}>
                                <FormGroup>
                                    <Label className="text-white pb-0 mb-0">Número</Label>
                                    <Input
                                        name="stretnumber"
                                        id="stretnumber"
                                        className="form-control form-control-sm pt-0 mt-0"
                                        type="text"
                                        value={stretNumber}
                                        onChange={(e) => {
                                            setStretNumber(e.target.value)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <FormGroup>
                                    <Label className="text-white pb-0 mb-0">Comuna</Label>
                                    <Input
                                        name="comuna"
                                        id="comuna"
                                        className="form-control form-control-sm pt-0 mt-0"
                                        type="text"
                                        value={comuna}
                                        onChange={(e) => {
                                            setComuna(e.target.value)
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={6}>
                                <FormGroup>
                                    <Label className="text-white pb-0 mb-0">Región</Label>
                                    <Input
                                        name="region"
                                        id="region"
                                        className="form-control form-control-sm pt-0 mt-0"
                                        type="text"
                                        value={region}
                                        onChange={(e) => {
                                            setRegion(e.target.value)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Container>
        </div>
    )
}
